import * as React from "react"
import {CheckCircleIcon} from '@heroicons/react/solid'
import Seo from "../../components/seo";
import BreadCrumbs from "../../components/BreadCrumbs";
import Layout from "../../components/layout"

const breadcrumbs = [
    {name: 'Lebenslauf', href: '/UeberMich/Lebenslauf', current: true},
]

const items = [
    'Versand an Dritte nur mit meiner Zustimmung',
    'Keine Vorstellung meines Profils ohne meine Zustimmung',
    'Aktualisiert bis: 11/2021'
]

export default function Lebenslauf() {
    return (
        <Layout>
            <Seo title="Meine Projekte"/>
            <BreadCrumbs pages={breadcrumbs}/>
            <div className="bg-gray-100">
                <div className="pt-12 sm:pt-16 lg:pt-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-base text-diit font-semibold tracking-wide uppercase">Dirk
                                Illenberger</h2>
                            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Mein
                                Lebenslauf</h2>
                            <p className="mt-4 text-xl text-gray-600">
                                Diese Information ist nur für Recruiter bestimmt, die Entwickler für ein
                                konkretes Software-Projekt suchen.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
                    <div className="relative">
                        <div className="absolute inset-0 h-1/2 bg-gray-100"/>
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div
                                className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                                <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                                    <h3 className="text-2xl font-extrabold text-gray-800 sm:text-3xl">Lebenslauf Download</h3>
                                    <p className="mt-6 text-base text-gray-500">
                                        Der Lebenslauf ist ein PDF, in dem keine Änderungen möglich sind und kein Kopieren in die Zwischenablage möglich ist.
                                        Recruiter, die das benötigen, wenden sich bitte direkt an mich.
                                    </p>
                                    <div className="mt-8">
                                        <div className="flex items-center">
                                            <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-diit">
                                                Wichtige Hinweise
                                            </h4>
                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                        </div>
                                        <ul
                                            className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                            {items.map((feature) => (
                                                <li key={feature} className="flex items-start lg:col-span-1">
                                                    <div className="flex-shrink-0">
                                                        <CheckCircleIcon className="h-5 w-5 text-green-400"
                                                                         aria-hidden="true"/>
                                                    </div>
                                                    <p className="ml-3 text-sm text-gray-700">{feature}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className="py-8 px-6 text-center bg-gray-200 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">


                                    <div className="mt-6">
                                        <div className="rounded-md shadow">
                                            <a
                                                href="#"
                                                className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-diit hover:bg-blue-800"
                                            >
                                                Download
                                            </a>
                                        </div>
                                    </div>
                                    <div className="mt-4 text-sm">
                                        <a href="/documents/Lebenslauf.pdf" className="font-medium text-gray-900">
                                            PDF-Version <span className="font-normal text-gray-600">(120 KB)</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
